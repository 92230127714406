import { Box, Stack, Button, Typography, useTheme, Divider } from "@mui/material";

const SignupSection = ({ sectionRef, openBookingDialog }) => {
    const theme = useTheme();

    return (
        <Box paddingX={{ xs: 2, sm: 4, md: 16 }} paddingY={8} sx={{ background: theme.palette.primary.main, alignItems: 'center', justifyContent: 'center', display: 'flex' }} ref={sectionRef}>
            <Stack spacing={8} width={{ sm: "100%", md: "80%" }} sx={{ alignItems: 'center', textAlign: 'center' }} marginY={8}>
                <Typography variant="h2" color="white">Start building your influence now</Typography>
                <Button color="white" variant="contained" size='large' onClick={openBookingDialog}>Join the waitlist</Button>
            </Stack>
        </Box>
    )
}

export default SignupSection;