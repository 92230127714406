import { Button, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";

const SignupSectionBottom = ({ sectionRef, openBookingDialog }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.up('xl'));

    return (
        <Stack
            ref={sectionRef}
            sx={{
                minHeight: '80vh',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center'
            }}
            spacing={12}
            paddingX={{ xs: 2, sm: 4, md: 16 }}
            paddingY={8}
        >
            {/* <Box sx={{alignItems: 'center', textAlign: 'center'}}> */}
            <Stack spacing={{ xs: 2, sm: 4, md: 6, lg: 8 }} sx={{ width: '100%', textAlign: 'center' }}>
                <Typography variant="h1" fontSize="8.0rem">
                    Grow your influence now
                </Typography>

                {/* <Stack direction={{ lg: 'column', xl: 'row' }} spacing={{lg: 0, xl: 8}} sx={{justifyContent: 'center', textAlign: 'center'}}>
                    <Typography variant="h1">Know</Typography>
                    <Typography variant="h1">more</Typography>
                </Stack>
                <Stack direction={{ lg: 'column', xl: 'row' }} spacing={{lg: 0, xl: 8}} sx={{justifyContent: 'center'}}>
                    <Typography variant="h1">Win</Typography>
                    <Typography variant="h1">more</Typography>
                </Stack> */}
            </Stack>

            <Button color='secondary' variant="contained" fontSize={'16px'} onClick={openBookingDialog}>Join the waitlist now</Button>
        </Stack>
    )
}

export default SignupSectionBottom;